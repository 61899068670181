<template>
	<div class="page">
		<Head />
		<!-- <div class="page-banner" :style="{'background-image': 'url('+banner+')'}"></div> -->
		<div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
		<div class="contact-wrap">
			<div class="container">
				<div class="clearfix">
					<div class="contact-item" v-if="company.contact_name">
						<span class="iconfont icon-tel"></span>
						<div class="contact-item-info">
							<p> {{company.contact_name}}</p>
						</div>
					</div>
					<div class="contact-item">
						<span class="iconfont icon-tel" v-if="company.tel1"></span>
						<div class="contact-item-info">
							<p> {{company.tel1}}</p>
						</div>
					</div>
					<div class="contact-item" v-if="company.tel2">
						<span class="iconfont icon-tel"></span>
						<div class="contact-item-info">
							<p> {{company.tel2}}</p>
						</div>
					</div>
					<div class="contact-item" v-if="lang=='zh-CN'">
						<span class="iconfont icon-phone"></span>
						<div class="contact-item-info">
							<p >{{company.phone}}</p>
						</div>
					</div>
					<div class="contact-item" v-if="lang=='en-US'">
						<span class="iconfont icon-phone" v-if="company.phone_en"></span>
						<div class="contact-item-info">
							<p >{{company.phone_en}}</p>
						</div>
					</div>
					<div class="contact-item" v-if="lang=='zh-CN'">
						<span class="iconfont icon-email"></span>
						<div class="contact-item-info">
							<p >{{company.email}}</p>
						</div>
					</div>
					<div class="contact-item" v-if="lang=='en-US'">
						<span class="iconfont icon-email"></span>
						<div class="contact-item-info">
							<p >{{company.email_en}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-for="(item,index) in pageitems" :key="index" class="{'z-warp': item.item_type == 'left', 'hot-product': item.item_type == 'Three_column', 'm-warp':item.item_type == 'analysis'}, 'section':item.item_type == 'picture'">
			<div class="container" v-if="item.item_type == 'left'">
				<div class="flex company-info">
					<div  class="company-info-content">
						<div class="company-info-tit">{{item.title}} </div>
						<div class="company-info-desc" >
							<div v-html="item.content"></div>
						</div>						
					</div>
					<div class="flex_bd  company-info-pic">
						<img :src="item.pic[0]">
					</div>
				</div>
			</div>
			<div class="section" v-if="item.item_type == 'picture'">
				<div class="section-head">
					<div  class="section-title">{{item.title}}</div>
					<div class="sec-line"></div>
				</div>
				<div class="section-body">
					<div class="container">
						<div class="com-box">
							<div class="clearfix">
								
								<div class="com-lt pull-left">
									<div class="com-pic">
										<img :src="item.pic[0]">
									</div>
									<el-row :gutter="29">
										<el-col :span="42">
											<div class="com-pic">
												<img :src="item.pic[1]">
											</div>
										</el-col>
										<el-col :span="58">
											<div class="com-pic">
												<img :src="item.pic[2]">
											</div>
										</el-col>
									</el-row>
								</div>
								<div class="com-rt pull-right">
									<div class="com-pic">
										<img v-if="lang=='en-US'" :src="item.pic[3]">
										<img v-if="lang=='zh-CN'" :src="item.pic[4]">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clearfix">
			<div class="map-box">
				<baidu-map class="map" :center="markerPoint" :zoom="16">
					<bm-marker :position="markerPoint" :dragging="true" @click="infoWindowOpen">
						<bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen"
							style="font-size:13px">
							<!-- 北京xxxx <br><br>
							地址：北京市 -->
						</bm-info-window>
					</bm-marker>
				</baidu-map>
			</div>
			<div class="words-box">
				<div class="msg-form">
					<h3 v-show="lang=='zh-CN'" class="msg-form-title">在线留言</h3>
					<h3 v-show="lang=='en-US'" class="msg-form-title">Online Message</h3>
					<el-form ref="form" :model="form">
						<el-form-item>
							<el-input v-model="form.name" :placeholder="placeholders.name"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="form.phone" :placeholder="placeholders.phone"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="form.email" :placeholder="placeholders.email"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input type="textarea" v-model="form.requirement" :placeholder="placeholders.desc"></el-input>
						</el-form-item>
						<el-form-item class="sub-item">
							<el-button type="warning" @click="onSubmit" class="sub-btn">{{placeholders.submit}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<Foot :nvlang="lang"/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import {mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				// lang:'',
				banner: require('../assets/images/contact_banner.jpg'),
				form: {
					name: '',
					phone: '',
					email: '',
					requirement: ''
				},
				placeholders:{},
				placeholders_cn:{
					name:'姓名',
					phone:'电话',
					email: '邮箱地址',
					desc: '请写下您的需求',
					submit:'提交',
				},
				placeholders_en:{
					name:'Your name',
					phone:'Your phone',
					email: 'Your email',
					desc: 'Please fill in what you need',
					submit:'Submit',
				},
				markerPoint: { 
					lng:  113.862915,
					lat: 22.576187
				},
				swiperOptionTop: {
					loop: true,
					loopedSlides: 1, // looped slides should be the same
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				// company:{
				// 	name:'深圳迈思康科技有限公司',
				// 	abbreviation: '迈思康',
				// 	en_name: 'Maxcomm Industrial Company Limited',
				// 	en_abbreviation: 'MaxComm',
				// 	address:'深圳宝安区沙井鑫鑫田工业区3栋1楼',
				// 	website:'www.maxcomm-tech.com',
				// 	adddress_en:'1F, Building 3, Xinxintian Ind. Park, Baoan District,Shenzhen CHINA',
				// 	contact:{
				// 		name: '乔海涛 ',
				// 		Position: '总经理',
				// 		tel1: '+86 755 2708 8245',
				// 		tel2:'+86 755 2936 1216',
				// 		phone:'181-2475-1388',
				// 		email:'nick@maxcomm-tech.com',
				// 		enname: 'Mr. Rick FANG',
				// 		enPosition: 'VP, Sales & Marketing Development',
				// 		entel1: '+86 755 2708 8245',
				// 		entel2:'+86 755 2936 1216',
				// 		enphone:'',
				// 		enemail:' rick@maxcomm-tech.com'
				// 	}
				// },
				contact:{
					// tel1:'86 755 2708 8245',
					// tel2:'86 755 2708 8245',
					// phone:'181-2475-1388',
					// email:'nick@maxcomm-tech.com'
				},
				show: false,
				messageok:''
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'en_id':state=>state.app.en_id,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			company(){
				return this.webcontents.base
			},
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'AboutUs')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}		
				
			},
			slide(){
				var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				if(slide && slide.length>0){
					// console.log(JSON.stringify(slide))
					return slide[0].pictures.split(',');
				}else{
					return [this.banner]
				}				
			},
			pageitems(){
				var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (let it of itemlist){
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					}else if(it.item_type !='picture' && it.item_type !='analysis'){
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it.pictures){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
		},
		watch: {
			lang:{
				handler(val){
					if(val == 'zh-CN'){
						this.placeholders = this.placeholders_cn;
						this.messageok = '需求提交成功！'
					}else{
						this.placeholders = this.placeholders_en;
						this.messageok = 'success！'
					}
				},
				immediate: true
			}
			
		},
		created() {
			// console.log(this.current)
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			onSubmit() {
				console.log('submit!');
				if(this.placeholders.submit != "提交" &&this.placeholders.submit != "Submit"){return}
				this.placeholders.submit = "提交中...";
				this.form.en_id = this.en_id;
				var that= this;
				this.$axios.post("https://api.cheyway.com/api/website/post_meassage",this.form).then(res=>{
					console.log(res)
					this.$message({
						type: 'info',
						message: that.messageok
					});
					that.form = {};
					that.placeholders.submit =that.lang== 'zh-CN'?"提交":"Submit";
				})
			},
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			}
			// goTo(url) {
			// 	this.$router.push(url);
			// }
		}
	}
</script>
